import { Country } from "@/types/Country";

export const countryCodeMap: Record<Country, string> = {
  at: "Austria",
  au: "Australia",
  be: "Belgium",
  bg: "Bulgaria",
  ca: "Canada",
  ch: "Switzerland",
  cy: "Cyprus",
  cz: "Czechia",
  de: "Deutschland",
  dk: "Denmark",
  ee: "Estonia",
  es: "Spain",
  fi: "Finland",
  fr: "France",
  gb: "Great Britain",
  gr: "Greece",
  hr: "Croatia",
  hu: "Hungary",
  ie: "Ireland",
  it: "Italy",
  jp: "Japan",
  kr: "South Korea",
  lt: "Lithuania",
  lu: "Luxembourg",
  lv: "Latvia",
  mt: "Malta",
  nl: "Netherlands",
  pl: "Poland",
  pt: "Portugal",
  qa: "Qatar",
  ro: "Romania",
  se: "Sweden",
  sg: "Singapore",
  si: "Slovenia",
  sk: "Slovakia",
  us: "United States"
};
