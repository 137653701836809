import {
  AlertMessage,
  Box,
  Button,
  CollapseGroup,
  CollapseItem,
  Divider,
  Link,
  Text,
  ThemeOverride
} from "@modernatx/ui-kit-react";
import { useRouter } from "next/router";
import React from "react";

import { useExperience } from "@/context/ExperienceContext";
import { FinderLocation } from "@/types/FinderLocation";
import { metersToLocaleString } from "@/utils/units";

import { FinderCheckboxProps } from "./FinderHeader";
import { useFinder } from "./hooks/useFinder";
import { customerWebsite, getGoogleMapsLink, stringToTitleCase } from "./utils";

const LocationTitle = ({
  distanceTemplate,
  location
}: {
  distanceTemplate: string;
  location: FinderLocation;
}): React.ReactNode => {
  const { streetAddress, distance, city, stateProvince, postalCode } = location.location;
  const { name, phoneNumber } = location.customer;
  const googleMapsLink = getGoogleMapsLink(name, streetAddress, city, stateProvince, postalCode);
  const { locale, country } = useExperience();
  const formattedDistance = React.useMemo(() => {
    if (!distance || !country || !locale) {
      return "";
    }

    const dist = metersToLocaleString(distance || 0, country, locale);

    return distanceTemplate.replace("{distance}", dist);
  }, [distance, distanceTemplate, country, locale]);

  return (
    <Box>
      <Text size="xl" sx={{ fontWeight: "bold" }}>
        {name}
      </Text>
      {streetAddress && (
        <Text sx={{ fontWeight: "light" }}>
          <br />
          {country === "jp" ? city + streetAddress : streetAddress}
        </Text>
      )}
      {phoneNumber && (
        <Text sx={{ fontWeight: "light" }}>
          <br />
          {phoneNumber}
        </Text>
      )}
      <Text size="sm" sx={{ fontWeight: "light" }}>
        <br />
        <Link href={googleMapsLink} type="external">
          {formattedDistance}
        </Link>
      </Text>
    </Box>
  );
};
const formatProductList = (products: string[]): string => {
  if (products.length === 1) {
    return products[0] || "";
  }
  return products.slice(0, -1).join(", ") + " and " + products[products.length - 1];
};
interface AvailabilityText {
  singular: string;
  plural: string;
}

const ProductBanner = ({
  availabilityText = {
    singular: "is available",
    plural: "are available"
  },
  selectedProducts,
  productOptions,
  productsAtLocation
}: {
  availabilityText?: AvailabilityText;
  selectedProducts: string[];
  productOptions: FinderCheckboxProps[];
  productsAtLocation: string[];
}) => {
  const defaultProductMappings = productOptions.reduce<{ [key: string]: string }>((acc, option) => {
    acc[option.name] = option.label;
    return acc;
  }, {});

  const selectedProductsAtLocation = productsAtLocation.filter((product) =>
    selectedProducts.includes(product)
  );

  const availableLongformNames = selectedProductsAtLocation.map(
    (product) => defaultProductMappings[product] || ""
  );

  const allSelectedProductsAvailable =
    selectedProductsAtLocation.length === selectedProducts.length;
  const noSelectedProducts = selectedProducts.length === 0;

  const bannerVariant = noSelectedProducts || allSelectedProductsAvailable ? "success" : "warning";

  let header = "";
  if (noSelectedProducts) {
    header = `${formatProductList(
      productsAtLocation.map(
        (shortName) => defaultProductMappings[shortName] || stringToTitleCase(shortName)
      )
    )} ${productsAtLocation.length > 1 ? availabilityText.plural : availabilityText.singular}`;
  } else if (allSelectedProductsAvailable) {
    header = `${formatProductList(availableLongformNames)} ${availableLongformNames.length > 1 ? availabilityText.plural : availabilityText.singular}`;
  } else if (availableLongformNames.length === 0) {
    header = "";
  } else {
    header = `Only ${formatProductList(availableLongformNames)} ${availableLongformNames.length > 1 ? "are" : "is"} available.`;
  }

  if (!!header) {
    return (
      <Box sx={{ pb: 4 }}>
        <AlertMessage variant={bannerVariant} header={header} />
      </Box>
    );
  }
};

export interface FinderListProps {
  availabilityText?: AvailabilityText;
  defaultOpen?: boolean;
  distanceTemplate: string;
  listTitle?: string;
  locations: FinderLocation[];
  scheduleNowText?: string;
  productOptions: FinderCheckboxProps[];
}

// FinderList renders the list of locations in the list view,
// irrespective of the selected view or size breakpoints
export const FinderList: React.FC<FinderListProps> = ({
  availabilityText,
  defaultOpen,
  distanceTemplate,
  listTitle = "Showing best results near",
  scheduleNowText = "Schedule Now",
  locations,
  productOptions
}) => {
  const { locationSelected, locationSelectedSet, place, productsSelected, searching } = useFinder();
  const locationRefs = React.useRef<(HTMLElement | null)[]>([]);
  const listRef = React.useRef<HTMLDivElement | null>(null);
  const { query } = useRouter();
  const { country, language } = useExperience();

  const isCurrentlySelectedLocation = React.useCallback(
    (location: FinderLocation) => {
      return locationSelected?.customer.id === location.customer.id;
    },
    [locationSelected]
  );

  // handle case for no selected products
  const countAvailableProducts = React.useCallback(
    (productsAtLocation: string[]) => {
      if (productsSelected.length === 0) {
        return productsAtLocation.length;
      }
      return productsSelected.filter((product) => productsAtLocation.includes(product)).length;
    },
    [productsSelected]
  );

  // Sort locations by the number of available products, otherwise sort by distance
  // if no products are selected, sort by distance only
  const sortedLocations = React.useMemo(() => {
    return locations.slice().sort((a, b) => {
      const aAvailableProducts = countAvailableProducts(a.products);
      const bAvailableProducts = countAvailableProducts(b.products);

      if (aAvailableProducts === bAvailableProducts) {
        return a.location.distance - b.location.distance;
      }

      return bAvailableProducts - aAvailableProducts;
    });
  }, [locations, countAvailableProducts]);

  // Scroll to the selected marker when it changes
  React.useEffect(() => {
    if (locationSelected) {
      const index = sortedLocations.findIndex(isCurrentlySelectedLocation);
      const locationItemRef = locationRefs.current[index];
      if (index !== -1 && locationItemRef) {
        const button = locationItemRef.querySelector("button");

        // If the CollapseItem is not expanded, expand it first then scroll to it
        // This is necessary because offsetTop will not be correctly calculated when the item is collapsed
        if (button) {
          const handleTransitionEnd = () => {
            locationItemRef.removeEventListener("transitionend", handleTransitionEnd);

            const listOffsetTop = listRef.current?.offsetTop || 0;
            const locationOffsetTop = locationItemRef.offsetTop || 0;

            listRef.current?.scrollTo({
              top: locationOffsetTop - listOffsetTop,
              behavior: "smooth"
            });
          };

          locationItemRef.addEventListener("transitionend", handleTransitionEnd);
          // only fire click event if not already expanded
          if (button.getAttribute("aria-expanded") === "false") {
            button.click();
          }
        }
      }
    }
  }, [isCurrentlySelectedLocation, locationSelected, sortedLocations]);

  const renderChildren = (location: FinderLocation) => {
    const children = [];
    if (
      location.products &&
      !location.products.includes("unbranded") &&
      productOptions.length > 1
    ) {
      children.push(
        <ProductBanner
          key="product-availability"
          availabilityText={availabilityText}
          productOptions={productOptions}
          selectedProducts={productsSelected}
          productsAtLocation={location.products}
        />
      );
    }
    if (location.customer.website) {
      children.push(
        <ThemeOverride key="schedule-now" mode="dark">
          <Box sx={{ pb: 4 }}>
            <Button
              icon="arrow-right"
              iconPosition="end"
              type=""
              size="small"
              href={customerWebsite(location.customer, language)}
            >
              {scheduleNowText}
            </Button>
          </Box>
        </ThemeOverride>
      );
    }
    return children.length > 0 ? children : null;
  };

  return (
    <Box
      sx={{
        background: "background01",
        borderRadius: [0, null, "large"],
        display: !!Object.keys(query).length && !searching && locations.length ? "flex" : "none",
        flexDirection: "column",
        height: "100%",
        flexShrink: 12,
        p: 4,
        pb: 0,
        pointerEvents: "all",
        width: "100%",
        alignItems: "start"
      }}
    >
      {!defaultOpen && !!place && !!locations.length && (
        <>
          <Box sx={{ pb: 4 }}>
            <Text size="xl" variant="bold">
              {country === "jp" ? `${place.address}${listTitle}` : `${listTitle} ${place.address}`}
            </Text>
          </Box>
          <Divider />
        </>
      )}
      <Box
        ref={listRef}
        sx={{
          width: "100%",
          height: "100%",
          py: 0,
          background: "background01",
          overflow: "auto",
          // @ts-ignore
          "::-webkit-scrollbar": {
            display: "none" /* Chrome, Safari, Opera */
          },
          msOverflowStyle: "none" /* IE and Edge */,
          scrollbarWidth: "none" /* Firefox */
        }}
      >
        <CollapseGroup size="md">
          {sortedLocations.map((location: FinderLocation, index: number) => (
            <Box
              key={location.customer.id}
              ref={(el) => (locationRefs.current[index] = el)}
              onClick={() => {
                locationSelectedSet(location);
              }}
            >
              <CollapseItem
                key={index}
                title={<LocationTitle location={location} distanceTemplate={distanceTemplate} />}
                defaultOpen={!!defaultOpen}
              >
                {renderChildren(location)}
              </CollapseItem>
            </Box>
          ))}
        </CollapseGroup>
      </Box>
    </Box>
  );
};
