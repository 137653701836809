import {
  Box,
  Checkbox,
  CheckboxProps,
  Grid,
  GridColumn,
  SearchBar,
  Text
} from "@modernatx/ui-kit-react";
import React from "react";

import { BlockTextProps } from "@/types/BlockText";

import { BlockText } from "../components/BlockText";
import { useFinder } from "./hooks/useFinder";
import { useLocationSearch } from "./hooks/useLocationSearch";

export type FinderCheckboxProps = Omit<CheckboxProps, "name" | "label"> & {
  label: string;
  labelDisplay?: BlockTextProps["text"];
  name: "2425-covid-children" | "2425-covid" | "2425-rsv";
};

export interface FinderHeaderBlockProps {
  text: BlockTextProps[];
  selectOptions?: FinderCheckboxProps[];
  searchText?: string;
  image?: string;
  imageAspectRatio?: number;
}

export const FinderHeader: React.FC<FinderHeaderBlockProps> = ({
  image,
  imageAspectRatio,
  searchText,
  selectOptions,
  text
}) => {
  const { productsSelect, productsSelected, searchOptionSelect, searchValue, searchValueSet } =
    useFinder();

  const handleCheckboxChange = (name: string) => {
    if (productsSelected.includes(name)) {
      productsSelect(productsSelected.filter((product) => product !== name));
    } else {
      productsSelect([...productsSelected, name]);
    }
  };

  const {
    searchSuggestionsList,
    showNoResults,
    handleSearchChange,
    handleSearchSubmit,
    handleSearchSuggestionClick
  } = useLocationSearch({
    searchValue,
    searchValueSet,
    searchOptionSelect
  });

  return (
    <Box>
      <Grid>
        <GridColumn size={[4, 8, 6]} sx={{ py: [10, 10, 15] }}>
          <BlockText text={text} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: [2, 2, 5],
              pb: 5,
              pt: 2
            }}
          >
            {selectOptions?.map((select, i) =>
              selectOptions.length === 1 ? (
                <Text key={i}>
                  <BlockText as="div" text={select.labelDisplay || select.label} />
                </Text>
              ) : (
                <Checkbox
                  key={i}
                  label={<BlockText as="div" text={select.label} />}
                  name={select.name}
                  value={productsSelected.includes(select.name)}
                  onChange={() => handleCheckboxChange(select.name)}
                />
              )
            )}
          </Box>
          <SearchBar
            label={searchText || "Search by city, state, or zip"}
            value={searchValue}
            dropdownOptions={searchSuggestionsList}
            type="select"
            showNoResults={showNoResults}
            onChange={handleSearchChange}
            onClickSearch={handleSearchSubmit}
            onSelectOption={handleSearchSuggestionClick}
          />
        </GridColumn>
        {!!imageAspectRatio && (
          <GridColumn
            size={[4, 8, 6]}
            sx={{
              display: ["none", "none", "flex"],
              position: "relative"
            }}
          >
            {image && <Box as="img" src={image} sx={{ height: "100%", position: "absolute" }} />}
          </GridColumn>
        )}
      </Grid>
    </Box>
  );
};
