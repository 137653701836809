import { ThemeOverride } from "@modernatx/ui-kit-react";
import React from "react";

import { useExperience } from "@/context/ExperienceContext";
import { useAvailableCountries } from "@/hooks/useAvailableCountries";
import { useAvailableLanguages } from "@/hooks/useAvailableLanguages";
import { CountryModalBlockProps, LanguageModalBlockProps } from "@/types/Block";
import { countryCodeMap } from "@/utils/country";
import { languageCodeMap } from "@/utils/language";

import { CountryModal } from "./CountryModal";
import { LanguageModal } from "./LanguageModal";
import { SplitButton } from "./SplitButton";
import { getFlagIcon } from "./utils";

interface LocaleSelectorProps {
  countryModal?: CountryModalBlockProps;
  languageModal?: LanguageModalBlockProps;
  size: "sm" | "md" | "lg";
}

export const LocaleSelector: React.FC<LocaleSelectorProps> = ({
  countryModal,
  languageModal,
  size
}) => {
  const { country: countryCode, language } = useExperience();
  const availableLanguages = useAvailableLanguages();
  const availableCountries = useAvailableCountries();
  const [countryModalVisible, setCountryModalVisible] = React.useState(false);
  const [languageModalVisible, setLanguageModalVisible] = React.useState(false);
  const handleCountryModalDismiss = React.useCallback(() => setCountryModalVisible(false), []);
  const handleCountryModalOpen = React.useCallback(() => setCountryModalVisible(true), []);
  const handleLanguageModalDismiss = React.useCallback(() => setLanguageModalVisible(false), []);
  const handleLanguageModalOpen = React.useCallback(() => setLanguageModalVisible(true), []);
  const countryName = countryCode ? countryCodeMap[countryCode] : undefined;
  const startIcon = countryCode ? getFlagIcon(countryCode) : undefined;
  const currentLanguageName = language ? languageCodeMap[language] : "";
  const countryModalAvailable = !!(countryName && countryModal && !!availableCountries.size);
  const languageModalAvailable = !!(
    currentLanguageName &&
    languageModal &&
    !!availableLanguages.size
  );

  return (
    <>
      {(availableCountries.size >= 1 || availableLanguages.size >= 1) && (
        <SplitButton
          endText={
            languageModal && availableLanguages.size >= 1
              ? [
                  {
                    display: "block",
                    text: currentLanguageName,
                    size,
                    weight: size === "lg" ? "bold" : null,
                    mt: size === "lg" ? 2 : null,
                    mb: size === "lg" ? 2 : null
                  }
                ]
              : undefined
          }
          startIcon={countryModalAvailable || languageModalAvailable ? startIcon : undefined}
          startText={
            countryModal
              ? [
                  {
                    display: "block",
                    text: countryName || "",
                    size,
                    weight: size === "lg" ? "bold" : null,
                    mt: size === "lg" ? 2 : null,
                    mb: size === "lg" ? 2 : null
                  }
                ]
              : undefined
          }
          onEndButtonClick={languageModalAvailable ? () => handleLanguageModalOpen() : undefined}
          onStartButtonClick={countryModalAvailable ? () => handleCountryModalOpen() : undefined}
        />
      )}
      <ThemeOverride mode="light">
        {countryModalAvailable && (
          <CountryModal
            {...countryModal}
            isOpen={countryModalVisible}
            onDismiss={handleCountryModalDismiss}
          />
        )}
        {languageModalAvailable && (
          <LanguageModal
            {...languageModal}
            isOpen={languageModalVisible}
            onDismiss={handleLanguageModalDismiss}
          />
        )}
      </ThemeOverride>
    </>
  );
};
